import { RuleSelector } from "../../components/ruleSelector/ruleSelector";
import { RulePathNames } from "../../enums/rulePathNames";
import { useOutletContext } from "react-router-dom";
import './rulesPage.scss'

export const RulesPage = () => {
    const { selectedRule } = useOutletContext<{ selectedRule: RulePathNames }>()
    const rulesNumberOfPages = 8;

    let imageMarkup = new Array(rulesNumberOfPages).fill('').map((_, index) => {
        return <img className="img-fluid py-2" src={`img/${selectedRule}/${selectedRule}-${index + 1}.png`} alt={`slide#${index + 1}`} />
    })

    return (
        <>
            <div className="rule-selector-container">
                <RuleSelector />
            </div>
            <div className="overflow-scroll d-grid justify-content-center">
                {imageMarkup}
            </div>
        </>
    );
}