import { useNavigate } from "react-router-dom";
import './navbar.scss'

export const NavBar = () => {
    const navigate = useNavigate()
    return (<header className="nav-bar" >
        <button id="home" className='btn btn-outline-transparent'><img src='img/TitleAndLogo.svg' alt="faroese flag" onClick={() => navigate('/')} /></button>
        <button id="home" className='btn btn-outline-transparent' onClick={() => navigate('/rules')}> Rules </button>
    </header >)
}



