import { useOutletContext } from "react-router-dom";
import { RulePathNames } from "../../enums/rulePathNames";
import "./ruleSelector.scss";

export const RuleSelector = () => {
    const { selectedRule, setSelectedRule } = useOutletContext<{ selectedRule: RulePathNames, setSelectedRule: (rule: RulePathNames) => void; }>();

    const selectedClass = 'border border-1 border-secondary shadow rounded-circle'

    return (
        <div className="rule-selector">
            <button className={`btn btn-outline-transparent border-0`}>
                <img
                    src="img/FlagFo.png"
                    alt="faroese flag"
                    className={`mx-auto ${selectedRule === RulePathNames.RulesFo ? selectedClass : ''}`}
                    onClick={() => setSelectedRule(RulePathNames.RulesFo)}
                />
            </button>
            <button className={`btn btn-outline-transparent  border-0`}>
                <img
                    src="img/FlagEn.png"
                    alt="english flag"
                    className={`mx-auto ${selectedRule === RulePathNames.RulesEn ? selectedClass : ''}`}
                    onClick={() => setSelectedRule(RulePathNames.RulesEn)}
                />
            </button>
            <button className={`btn btn-outline-transparent  border-0`}>
                <img
                    src="img/FlagDk.png"
                    alt="danish flag"
                    className={`mx-auto ${selectedRule === RulePathNames.RulesDk ? selectedClass : ''}`}
                    onClick={() => setSelectedRule(RulePathNames.RulesDk)}
                />
            </button>
        </div>
    );
};
