import { Route, Routes } from 'react-router-dom'
import { RulesPage } from './pages/rules/rulesPage'
import { Home } from './pages/home/homePage'
import { App } from './App'

export const AppRouter = () => (
    <Routes>
        <Route element={<App />}>
            <Route index element={<Home />} />
            <Route path="/rules" element={<RulesPage />} />
        </Route>
    </Routes>
)
